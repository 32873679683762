import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { PreprocessorConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccessGroupZoneService } from './api/access-group-zone.service';
import { DeviceService } from './api/device.service';
import { DeviceAccessGroupService } from './api/device-access-group.service';
import { DeviceCapabilitiesService } from './api/device-capabilities.service';
import { DeviceMetadataService } from './api/device-metadata.service';
import { DeviceProvidersService } from './api/device-providers.service';
import { GeoFencesService } from './api/geo-fences.service';
import { ProcessAddressLocationService } from './api/process-address-location.service';
import { ZonesService } from './api/zones.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class PreprocessorApiModule {
    public static forRoot(configurationFactory: () => PreprocessorConfiguration): ModuleWithProviders<PreprocessorApiModule> {
        return {
            ngModule: PreprocessorApiModule,
            providers: [ { provide: PreprocessorConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: PreprocessorApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('PreprocessorApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
