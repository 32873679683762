import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { SettingsConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ApplicationSettingValuesService } from './api/application-setting-values.service';
import { DefaultUserSettingValuesService } from './api/default-user-setting-values.service';
import { SettingsService } from './api/settings.service';
import { UserSettingValuesService } from './api/user-setting-values.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class SettingsApiModule {
    public static forRoot(configurationFactory: () => SettingsConfiguration): ModuleWithProviders<SettingsApiModule> {
        return {
            ngModule: SettingsApiModule,
            providers: [ { provide: SettingsConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: SettingsApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('SettingsApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
